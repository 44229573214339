import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'src/context/auth.context';

const LoadingComponent = () => {
    return (
        <div className="pt-3 text-center" >
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div >
    )
}

export default function AuthGuard({ children }) {
    const { loading } = useAuth();

    return (
        <React.Fragment>
            {loading ? <LoadingComponent /> : <Container> {children}</Container>}
        </React.Fragment>
    );
}

// ----------------------------------------------

function Container({ children }) {
    const history = useHistory();

    const { currentUser } = useAuth();

    const [checked, setChecked] = React.useState(false);

    const check = React.useCallback(() => {
        if (!currentUser) {
            history.replace("/login");
        } else {
            console.log("acá, no?")
            setChecked(true);
        }
    }, [currentUser, history]);

    React.useEffect(() => {
        check();
    }, [currentUser, check]);

    if (!checked) {
        return null;
    }

    return <React.Fragment>  {children}</React.Fragment>;
}
