import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

const { REACT_APP_API_URL: API_URL } = process.env;


export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getUser = async (token) => {
    try {
      setLoading(true)

      if (!token) {
        return;
      }

      const res = await fetch(`${API_URL}auth/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      });

      const data = await res.json()
      const { type } = data;
      setCurrentUser({ token, type });

      setLoading(false)
    } catch (error) {
      logout()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      getUser(token)
    }
  }, []);

  const login = ({ token, type }) => {
    localStorage.setItem("userToken", token);
    setCurrentUser({ token, type });
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    setCurrentUser(null);
    history.replace("/login")
  };

  const value = {
    currentUser,
    login,
    loading,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
